import React, { useEffect, useState } from 'react';
import { connect, OrderModelState, Dispatch, Loading, history } from 'umi';
import { PlusOutlined } from '@ant-design/icons';
import {
  Spin,
  Radio,
  Collapse,
  Button,
  Checkbox,
  Form,
  Input,
  Upload,
  Image,
} from 'antd';
const Panel = Collapse.Panel;

import QRCode from 'qrcode.react';
import MyUpload from '@/components/Upload/Upload';
import zhifubao from '@/assets/order/zhifubao.png';
import weixin from '@/assets/order/weixin.png';
import wx from '@/assets/order/wx.png';
import gdg from '@/assets/order/gdg.png';
import zfb from '@/assets/order/zfb.png';
import saoyisaoIcon from '@/assets/order/saoyisao.png';
import moment from 'moment';
import { useLeftTime, copy } from '@/utils';
import { BASE_URL_H5 } from '@/config/api';

import './Pay.less';
interface IProps {
  dispatch: Dispatch;
  order: OrderModelState;
  loading: Loading;
}
interface IPayInfo {
  expireTime: number;
  num: number;
  price: number;
  payPrice: number;
  title: string;
  orderIds: any[];
}
let timer: NodeJS.Timeout;
let payTimer: NodeJS.Timeout;
let failCount: number = 0;
let oldPayInfo: IPayInfo = {};
let bankInfo = {};
function OrderPay(props: IProps) {
  const [form] = Form.useForm();

  const [leftTime, setLeftTime] = useState('--');
  const [payInfo, setPayInfo] = useState<IPayInfo>({
    expireTime: 0,
    num: 0,
    price: 0,
    title: '--',
    orderIds: [],
    payPrice: 0,
  });
  const [alipayUrl, setAlipayUrl] = useState<string>('');
  const [payTypeVal, setPayTypeVal] = useState<number>(1);
  const [orderIds, setOrderIds] = useState([]);
  const [urls, seturls] = useState(true);

  const handleRadioChange = (e: any) => {
    const val = e.target.value;
    setPayTypeVal(val);
    clearInterval(payTimer);
  };
  useEffect(() => {
    orderIds.length > 0 && getOrderStatus(orderIds);
    getoldPayInfo();
  }, [payTypeVal]);
  useEffect(() => {
    if (!orderIds || !orderIds.length) {
      return;
    }
    if (
      oldPayInfo === null ||
      oldPayInfo.payTypeVal !== payInfo.payTypeVal ||
      oldPayInfo.parentOrderNo !== payInfo.parentOrderNo
    ) {
      if (payTypeVal === 1 && payInfo.orderNos) {
        if (payInfo.orderNos.length > 1) {
          getAlipayQrUrl(orderIds, payInfo.parentOrderNo, 'WECHAT_LLIAN_SCAN');
        } else {
          getAlipayQrUrl(
            orderIds,
            payInfo.orderNos.toString(),
            'WECHAT_LLIAN_SCAN',
          );
        }
      } else if (payTypeVal === 2 && payInfo.orderNos) {
        if (payInfo.orderNos.length > 1) {
          getAlipayQrUrl(orderIds, payInfo.parentOrderNo, 'ALI_NATIVE');
        } else {
          getAlipayQrUrl(orderIds, payInfo.orderNos.toString(), 'ALI_NATIVE');
        }
        console.log('zfb');
      }
    }
    if (
      !oldPayInfo.parentOrderNo ||
      oldPayInfo.payTypeVal !== payInfo.payTypeVal
    ) {
      oldPayInfo = payInfo;
    }
  }, [payInfo, orderIds]);
  function getoldPayInfo() {
    props
      .dispatch({
        type: 'order/transferOrder',
      })
      .then((data: any) => {
        bankInfo = data;
        console.log('data', bankInfo, '555', data);
      })
      .catch(() => {});
  }

  function getOrderStatus(ids: any) {
    if (history.location.pathname.indexOf('/order/pay') === -1) {
      return;
    }
    props
      .dispatch({
        type: 'order/getOrderStatus',
        payload: {
          orderIds: ids,
        },
      })
      .then((data: any) => {
        const { status } = data;
        setPayInfo({ ...data, payTypeVal });
        let end = moment(data.gmtCreate + 30 * 60 * 1000);
        timer = setInterval(() => {
          const diff = moment(end).diff(moment(), 'ms');
          // if (diff < 0) {
          //   history.replace('/');
          // }
          const [HH, mm, ss] = useLeftTime(diff);
          setLeftTime(`${HH}时${mm}分${ss}秒`);
        }, 500);
        if (status === 15) {
          setPayTypeVal(2);
        }
        if (status === 10) {
          payTimer = setTimeout(() => {
            getOrderStatus(ids);
          }, 5000);
        } else if (status === 20) {
          history.replace('/order/pay/result/' + JSON.stringify(ids));
        }
      })
      .catch(() => {
        payTimer = setTimeout(() => {
          getOrderStatus(ids);
        }, 5000);
      });
  }
  function getAlipayQrUrl(
    orderIds: any,
    parentOrderNo: any,
    payChannelEnum: string,
  ) {
    if (!parentOrderNo) {
      return;
    }
    props
      .dispatch({
        type: 'order/payment',
        payload: {
          orderIds,
          parentOrderNo,
          payChannelEnum,
          payType: 2,
        },
      })
      .then((data: any) => {
        console.log('data', data);

        if (data) {
          setAlipayUrl(data);
        }
      })
      .catch(() => {});
  }
  function changeCollapse(e: any) {
    const val = Number(e);
    setPayTypeVal(val);
    clearInterval(payTimer);
  }
  useEffect(() => {
    const paramsArray = history.location.pathname.split('/');
    let orderIdsArr: any = paramsArray[paramsArray.length - 1];
    orderIdsArr = JSON.parse(orderIdsArr);
    setOrderIds(orderIdsArr);
    getOrderStatus(orderIdsArr);

    return () => {
      clearInterval(timer);
      clearTimeout(payTimer);
    };
  }, []);

  useEffect(() => {
    if (leftTime === '00时00分00秒' && payInfo.status !== 15) {
      history.replace('/');
    }
  }, [leftTime]);
  const onFinish = (values) => {
    const parentOrderNo = payInfo.parentOrderNo;

    props
      .dispatch({
        type: 'order/transferPay',
        payload: {
          orderIds,
          parentOrderNo,
          ...values,
          payChannelEnum: 'OFFLINE_BANK',
          payType: 2,
        },
      })
      .then((res) => {
        if (res.code == 200) {
          history.replace('/order/pay/result/' + JSON.stringify(orderIds));
        }
        console.log(res);
      });
    console.log('Success:', values);
  };
  const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo);
  };
  const onChangeUpload = (value) => {
    if (value.length) {
      form.setFieldValue('urls', value);
      seturls(false);
    } else {
      seturls(true);
    }

    console.log(urls, value, 'valuevaluevalue');
  };
  return (
    <div className="order-pay-container">
      <div className="section">
        <div className="__title-con">
          <h1>收银台</h1>
        </div>
        <div className="checkout-con">
          <div className="info-con">
            <div className="l">
              <h1>
                {payInfo.orderIds && payInfo.orderIds.length > 0
                  ? `共${payInfo.orderIds.length}笔订单，提交成功！`
                  : ''}
              </h1>
              {/* <p>
                请您于<span className="primary-color"> </span>
                内完成支付<span className="grey-color"> (逾期将被取消)</span>
              </p> */}
            </div>
            <div className="r">
              <p>
                实付金额：
                <span className="primary-color">¥{payInfo.payPrice}</span>
              </p>
            </div>
          </div>
        </div>
        <div className="pay-type-list">
          <div className="pay-type-item">
            <div className="title-con">
              <div className="l">
                <p>选择支付方式</p>
              </div>
            </div>
            <div className="content">
              <Collapse
                activeKey={[payTypeVal]}
                expandIconPosition="end"
                accordion
                onChange={changeCollapse}
              >
                <Panel
                  collapsible={payInfo.status === 15 ? 'disabled' : ''}
                  header={
                    <Radio.Group
                      name="radiogroup"
                      className="pay-type"
                      value={payTypeVal}
                      onChange={handleRadioChange}
                    >
                      <Radio value={1} disabled={payInfo.status === 15}>
                        <img className="pay-type-icon" src={wx} alt="" />
                        <span>微信支付</span>
                      </Radio>
                      {/* <Radio value={2}>
                    <img className="pay-type-icon" src={zfb} alt="" />
                    <span>支付宝</span>
                  </Radio> */}
                    </Radio.Group>
                  }
                  key="1"
                  style={{
                    background: '#fff',
                    border: 'none',
                  }}
                >
                  <div className="qrcode-container">
                    <p className="qrcode-tip">
                      距离二维码过期还剩<span className="ss">{leftTime} </span>
                      ，过期后请刷新页面重新获取二维码
                    </p>
                    <div className="qrcode-box">
                      <Spin spinning={props.loading.effects['order/payment']}>
                        <div className="qr-con">
                          <div className="qr-con-wrapper">
                            <QRCode
                              className="qr"
                              size={190}
                              value={alipayUrl}
                            />
                          </div>
                        </div>
                        <p>杭州赛旺电子商务科技有限公司</p>
                      </Spin>

                      <div className="qr-con-tip">
                        <img
                          className="scan-icon"
                          src={payTypeVal === 1 ? weixin : zhifubao}
                          alt=""
                        />
                        <div className="tip-con">
                          <h1>
                            请打开{payTypeVal === 1 ? '微信' : '支付宝'}扫一扫
                          </h1>
                          <p>
                            首次使用请下载手机
                            {payTypeVal === 1 ? '微信' : '支付宝'}
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="gdgBoxWx">
                      <div className="gdgBoxTitle">注意事项</div>
                      <div className="gdgConte">
                        <ul className="Lookout">
                          <li>单笔限额：50000.00元，单日限额50000.00元</li>
                          <li>单月限额：100000.00</li>
                          <li>如若超出单日限额货单月限额，请咨询平台客服</li>
                        </ul>
                      </div>
                    </div>
                  </div>

                  <div className="gdgBox">
                    <div className="gdgBoxTitle">资金流向</div>
                    <div className="gdgConte">
                      <div className="direction">
                        <div className="dashed "></div>
                        <div>
                          <img
                            src={require('@/assets/order/yhwx.png')}
                            alt=""
                          />
                          <p>微信</p>
                        </div>
                        <div>
                          <img
                            src={require('@/assets/order/right.png')}
                            alt=""
                          />
                          <p className="huikuan">划款</p>
                        </div>
                        <div>
                          <img src={require('@/assets/order/dsk.png')} alt="" />
                          <p>平台代收款</p>
                        </div>
                        <div className="Receive">
                          <div className="ReceiveConte">
                            <img
                              src={require('@/assets/order/xz.png')}
                              alt=""
                            />{' '}
                            <span>买家确认收货</span>
                          </div>
                          <img
                            src={require('@/assets/order/right.png')}
                            alt=""
                          />
                          <p className="huikuan">分账</p>
                        </div>
                        <div>
                          <img
                            src={require('@/assets/order/ryyh.png')}
                            alt=""
                          />
                          <p>供应商银行账户</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </Panel>
                <Panel
                  header={
                    <Radio.Group
                      name="radiogroup"
                      className="pay-type"
                      value={payTypeVal}
                      onChange={handleRadioChange}
                    >
                      <Radio value={2}>
                        <img className="pay-type-icon" src={gdg} alt="" />
                        <span>对公转账（支持公对公转账）</span>
                      </Radio>
                    </Radio.Group>
                  }
                  key="2"
                  style={{
                    background: '#fff',
                    border: 'none',
                  }}
                >
                  <div className="gdgBox">
                    <div className="gdgConte">
                      <div className="contenLeft">
                        <div className="rightTitle">
                          <img
                            src={require('@/assets/order/czjl.png')}
                            alt=""
                          />
                          <span>操作步骤</span>
                        </div>
                        <div>
                          <img
                            className="sortImg"
                            src={require('@/assets/order/sort.png')}
                            alt=""
                          />
                        </div>
                        <ul>
                          <li style={{ marginTop: '18px' }}>
                            <span>打开手机银行APP/PC网银，或前往银行</span>
                          </li>
                          <li className="jiaochengboix">
                            <span>用您的银行卡向以下账户进行转帐</span>
                            <div className="jacheng">
                              <div>
                                <section>
                                  <span className="name">公司名称</span>
                                  <span className="conten">
                                    {bankInfo.accountName}
                                  </span>
                                </section>
                                <span
                                  className="btn"
                                  onClick={() => {
                                    copy(bankInfo.accountName);
                                  }}
                                >
                                  复制
                                </span>
                              </div>
                              <div>
                                <section>
                                  <span className="name">收款账户</span>
                                  <span
                                    className="conten"
                                    style={{ color: '#0277FF' }}
                                  >
                                    {bankInfo.accountNo}
                                  </span>
                                </section>

                                <span
                                  className="btn"
                                  onClick={() => {
                                    copy(bankInfo.accountNo);
                                  }}
                                >
                                  复制
                                </span>
                              </div>
                              <div>
                                <section>
                                  <span className="name">收款银行</span>
                                  <span className="conten">
                                    {bankInfo.accountBank}
                                  </span>
                                </section>
                              </div>
                              <div>
                                <section>
                                  <span className="name">所在地</span>
                                  <span className="conten">
                                    {bankInfo.address}
                                  </span>
                                </section>
                              </div>
                              <div>
                                <section>
                                  <span className="name">开户行</span>
                                  <span className="conten">
                                    {bankInfo.unionNo}
                                  </span>
                                </section>

                                <span
                                  className="btn"
                                  onClick={() => {
                                    copy(bankInfo.unionNo);
                                  }}
                                >
                                  复制
                                </span>
                              </div>
                            </div>
                          </li>
                          <li>
                            <img
                              src={require('@/assets/order/three.png')}
                              alt=""
                            />
                            <div className="tsBox">
                              <span>
                                转账成功，上传付款凭证，系统审核手动核销
                              </span>
                              <div className="ts">
                                工作时间系统审核时间预计48小时内，非工作日内可能有延迟
                              </div>
                            </div>
                          </li>
                          <li>
                            <img
                              src={require('@/assets/order/three.png')}
                              alt=""
                            />
                            <div
                              className="tsBox"
                              style={{ marginTop: '10px' }}
                            >
                              <span>核销通过，转账成功</span>
                            </div>
                          </li>
                        </ul>
                        <div className="gdgBoxtext">
                          <div className="gdgBoxTitle">注意事项</div>
                          <div className="gdgConte">
                            <ul className="Lookout">
                              <li className="montyli">
                                转账金额与订单金额务必保持一致，若不一致，则系统审核将会失败，您后续需要重新支付该订单
                              </li>
                              <li>
                                在银行转账页面若无法找到收款银行，可咨询“平台客服”
                              </li>
                              <li>如有其他问题，可咨询“平台客服”</li>
                              <li>
                                平台只作为代收款方，申请开票可直接联系供应商或咨询平台客服
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                      <img
                        className="rightImg"
                        src={require('@/assets/order/home_icon_yc.png')}
                        alt=""
                      />
                      <div className="contenRight">
                        <div className="rightTitle">
                          <img
                            src={require('@/assets/order/xsls.png')}
                            alt=""
                          />{' '}
                          <span>付款方信息</span>
                        </div>
                        <Form
                          form={form}
                          labelAlign="left"
                          style={{
                            margin: '16px 0 0 0 ',
                          }}
                          initialValues={{
                            remember: true,
                          }}
                          labelCol={{ span: 7 }}
                          onFinish={onFinish}
                          onFinishFailed={onFinishFailed}
                          autoComplete="off"
                        >
                          <Form.Item
                            label="付款方"
                            name="payAccount"
                            rules={[
                              {
                                required: true,
                                message: '请输入付款方!',
                              },
                            ]}
                          >
                            <Input placeholder="请输入付款方单位全称" />
                          </Form.Item>

                          <Form.Item
                            label="转账金额(元)"
                            name="amount"
                            rules={[
                              {
                                required: true,
                                message: '请输入转账金额(元)!',
                              },
                            ]}
                          >
                            <Input placeholder="请输入转账金额" />
                          </Form.Item>

                          <Form.Item label="付款备注" name="payerMessage">
                            <Input.TextArea placeholder="请输入付款备注" />
                          </Form.Item>
                          <Form.Item
                            label="转账凭证"
                            extra="可上传10张图片，只支持jpg、jpeg"
                            name="urls"
                            rules={[
                              {
                                required: true,
                                message: '请上传转账凭证!',
                              },
                            ]}
                          >
                            <div style={{ display: 'flex' }}>
                              <MyUpload
                                uploadTitle="凭证上传"
                                size={24}
                                limitNumber={10}
                                onChange={onChangeUpload}
                                maxSize={5}
                              ></MyUpload>

                              {urls && (
                                <div>
                                  <Image
                                    src={require('@/assets/order/shilie.png')}
                                  />
                                  <p
                                    style={{
                                      textAlign: 'center',
                                      marginTop: '10px',
                                    }}
                                  >
                                    示例
                                  </p>
                                </div>
                              )}
                            </div>
                          </Form.Item>
                          <Form.Item>
                            <Button
                              type="primary"
                              style={{
                                borderRadius: '4px',
                                width: '161px',
                                height: '46px',
                                marginLeft: '123px',
                              }}
                              htmlType="submit"
                            >
                              确认上传
                            </Button>
                          </Form.Item>
                        </Form>
                      </div>
                    </div>
                  </div>

                  <div className="gdgBox">
                    <div className="gdgBoxTitle">银行资金流向</div>
                    <div className="gdgConte">
                      <div className="direction">
                        <div className="dashed "></div>
                        <div>
                          <img
                            src={require('@/assets/order/jszh.png')}
                            alt=""
                          />
                          <p>大客户银行账户</p>
                        </div>
                        <div>
                          <img
                            src={require('@/assets/order/right.png')}
                            alt=""
                          />
                          <p className="huikuan">汇款</p>
                        </div>
                        <div>
                          <img src={require('@/assets/order/dsk.png')} alt="" />
                          <p>平台代收款</p>
                        </div>
                        <div className="Receive">
                          <div className="ReceiveConte">
                            <img
                              src={require('@/assets/order/xz.png')}
                              alt=""
                            />{' '}
                            <span>买家确认收货</span>
                          </div>
                          <img
                            src={require('@/assets/order/right.png')}
                            alt=""
                          />
                          <p className="huikuan">划款</p>
                        </div>
                        <div>
                          <img
                            src={require('@/assets/order/ryyh.png')}
                            alt=""
                          />
                          <p>供应商结算银行账户</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </Panel>
              </Collapse>
              <div className="pay-type-container"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
export default connect(
  ({ order, loading }: { order: OrderModelState; loading: Loading }) => ({
    order,
    loading,
  }),
)(OrderPay);
